import React, { useContext } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container, Button } from 'components/common';
import dev from 'assets/illustrations/skills.svg';
import { Wrapper, SkillsWrapper, Details, Thumbnail } from './styles';

export const Skills = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <Wrapper id="about">
      <SkillsWrapper as={Container}>
        <Thumbnail>
          <img src={dev} alt="My name's Olavo and I'm a Front-End Engineer from Brazil." />
        </Thumbnail>
        <Details theme={theme}>
          <h1>More about me</h1>
          <p>
            I' m a very passionate person giving myself completely to my activities from the simplest to the hardest.
            Love for music, movies, nature and computer games is a must. Always seeing the world from different
            perspectives.
          </p>
          <Button as={AnchorLink} href="#contact">
            Contact me
          </Button>
        </Details>
      </SkillsWrapper>
    </Wrapper>
  );
};
