import React, { useEffect } from 'react';
import useHotjar from 'react-use-hotjar';
import { Layout, SEO } from 'components/common';
import { Intro, Skills, Contact, Projects } from 'components/landing';

export default () => {
  const { initHotjar } = useHotjar();

  useEffect(() => {
    initHotjar(2300296, 6);
  }, [initHotjar]);

  return (
    <Layout>
      <SEO />
      <Intro />
      <Projects />
      <Skills />
      <Contact />
    </Layout>
  );
};
