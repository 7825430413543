import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 2rem 0;
`;

export const Grid = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 8fr;
  gap: 1.2rem 1.2rem;

  @media (max-width: 960px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 680px) {
    grid-template-columns: 1fr;
  }
`;

export const Item = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.11);

  h4 {
    color: ${({ theme }) => (theme === 'light' ? '#212121' : '#fff')};
  }

  p {
    color: ${({ theme }) => (theme === 'light' ? '#707070' : '#c7c7c7')};
  }
`;

export const Content = styled.div`
  padding: 1rem 0;
  min-height: 160px;
`;

export const Stats = styled.div`
  display: flex;
  align-items: center;

  div {
    display: flex;
    &:first-child,
    &:nth-child(2) {
      margin-right: 0.5rem;
    }

    &:last-child {
      margin-left: 0.5rem;
      a {
        line-height: 1;
        cursor: pointer;
      }
    }

    img {
      margin: 0;
    }

    &:not:last-child svg path {
      fill: ${({ theme }) => (theme === 'light' ? '#000' : '#fff')};
    }

    span {
      color: ${({ theme }) => (theme === 'light' ? '#000' : '#fff')};
      margin-left: 0.5rem;
    }
  }
`;

export const Languages = styled.div`
  justify-content: flex-end;
  flex-wrap: wrap;
  opacity: 0.5;
  font-size: 14px;

  span {
    display: flex;
  }
`;

export const Iframe = styled.iframe`
  position: fixed;
  width: calc(100vw - 4rem);
  height: calc(100vh - 2rem);
  top: 50%;
  left: 50%;
  border: none;
  transform: translate(-50%, -50%) scale(0.8);
  background: #fafafa;
  box-shadow: 1px 2px 2px 1px rgb(0 0 0 / 30%);

  @media (max-width: 680px) {
    position: fixed;
    width: calc(100% - 3rem);
    height: calc(100% - 6rem);
    top: 3rem;
    left: 1.5rem;
    border: none;
    z-index: 5;
    background: #fafafa;
    box-shadow: 1px 2px 2px 1px rgb(0 0 0 / 30%);
    transform: none;
  }
`;
