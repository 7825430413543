import React, { useCallback, useContext, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container, Card, TitleWrap } from 'components/common';
import Star from 'components/common/Icons/Star';
import Fork from 'components/common/Icons/Fork';
import Npm from 'components/common/Icons/Npm';
import Preview from 'components/common/Icons/Preview';
import { useModalWrapper } from 'hooks/useModal';
import { Wrapper, Grid, Item, Content, Stats, Languages, Iframe } from './styles';

export const Projects = () => {
  const { ModalWrapper, isOpen, openModal } = useModalWrapper({
    bindToElement: typeof window !== 'undefined' ? window.document.body : undefined,
  });
  const previewdProject = useRef(null);
  const iframeRef = useRef(null);
  const { theme } = useContext(ThemeContext);
  const {
    github: {
      viewer: {
        repositories: { edges },
      },
    },
  } = useStaticQuery(
    graphql`
      {
        github {
          viewer {
            repositories(first: 25, orderBy: { field: STARGAZERS, direction: DESC }) {
              edges {
                node {
                  id
                  name
                  url
                  description
                  viewerHasStarred
                  homepageUrl
                  stargazers {
                    totalCount
                  }
                  forkCount
                  languages(first: 3) {
                    nodes {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  const handlePreviewCallback = useCallback(
    node => {
      previewdProject.current = node;
      openModal();
    },
    [openModal]
  );

  const handlePreviewKeyDown = useCallback(
    (e, node) => {
      e.persist();
      if (e.key === 'Enter') {
        previewdProject.current = node;
        openModal();
      }
    },
    [openModal]
  );

  const isItNpmPage = homepageUrl => homepageUrl.indexOf('npmjs') > 0;

  const hasOwnerStarred = ({ node }) => true && node.viewerHasStarred;

  return (
    <Wrapper as={Container} id="projects">
      <h2>Projects</h2>
      <Grid>
        {edges.filter(hasOwnerStarred).map(({ node }) => (
          <Item key={node.id} theme={theme}>
            <Card theme={theme}>
              <Content as="a" href={node.url} target="_blank" rel="noopener noreferrer">
                <h4>{node.name}</h4>
                <p>{node.description}</p>
              </Content>
              <TitleWrap>
                <Stats theme={theme}>
                  <div>
                    <a href={`${node.url}/stargazers`} target="_blank" rel="noopener noreferrer">
                      <Star color={theme === 'light' ? '#000' : '#fff'} />
                    </a>
                    <span>{node.stargazers.totalCount}</span>
                  </div>
                  <div>
                    <a href={`${node.url}/network/members`} target="_blank" rel="noopener noreferrer">
                      <Fork color={theme === 'light' ? '#000' : '#fff'} />
                    </a>
                    <span>{node.forkCount}</span>
                  </div>
                  {node.homepageUrl && isItNpmPage(node.homepageUrl) && (
                    <div>
                      <a href={node.homepageUrl} target="_blank" rel="noopener noreferrer">
                        <Npm />
                      </a>
                    </div>
                  )}
                  {node.homepageUrl && !isItNpmPage(node.homepageUrl) && (
                    <div>
                      <a
                        role="button"
                        tabIndex={0}
                        onKeyDown={e => handlePreviewKeyDown(e, node)}
                        onClick={() => handlePreviewCallback(node)}
                      >
                        <Preview color={theme === 'light' ? '#000' : '#fff'} />
                      </a>
                    </div>
                  )}
                </Stats>
                <Stats theme={theme}>
                  <Languages>
                    {node.languages.nodes.map(({ id, name }) => (
                      <span key={id}>{name}</span>
                    ))}
                  </Languages>
                </Stats>
              </TitleWrap>
            </Card>
          </Item>
        ))}
      </Grid>
      {isOpen && (
        <ModalWrapper backdrop>
          <Iframe
            ref={iframeRef}
            id={previewdProject.current.name}
            src={previewdProject.current.homepageUrl}
            title={previewdProject.current.name}
          />
        </ModalWrapper>
      )}
    </Wrapper>
  );
};
